import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const metaTags = {
  description: 'Side effects may occur, view Important Safety Information.',
  keywords: 'Homepage',
  title: 'Potential Side Effects | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const getHeaderHeight = () => {

  const headerHeight = document.getElementsByClassName('base-header')[0].offsetHeight

  return headerHeight 
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Potential side effects</h1>
        <h2>SUPPRELIN<sup>&reg;</sup> LA, like similar medicines, may cause a temporary increase in some hormones</h2>
        <ul className='brand-list'>
            <li>You may notice more signs of puberty in the first week of treatment and new signs may develop. You should see evidence that puberty is stopping within 4 weeks of treatment</li>
            <li>The most common side effects of SUPPRELIN<sup>&reg;</sup> LA are skin reactions at the place where the implant is inserted. Such reactions may include bruising, soreness, pain, tingling, itching, and swelling. They usually go away without treatment within 2 weeks</li>
            <li>Call your child’s healthcare professional if he or she has bleeding, redness, or pain at the insertion site</li>
            <li>Serious and life-threatening allergic reactions have happened with GnRH medicines (the type of medicine in SUPPRELIN<sup>&reg;</sup> LA)</li>
            <li>Some people taking GnRH agonists like SUPPRELIN<sup>&reg;</sup> LA have had new or worsening mental (psychiatric) problems and seizures. Call your child's doctor right away if they experience these events</li>
        </ul>
        <p>Ask your child's healthcare professional for more information or see <strong><AnchorLink offset={getHeaderHeight} href='#isi_jump'>Important Safety Information</AnchorLink></strong> for further details.</p>
        <h2>These may not be all the side effects of SUPPRELIN<sup>&reg;</sup> LA </h2>
        <p>This information does not replace counseling from a healthcare professional. </p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
      <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one caregiver's kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/patients/caregiver-resources/#video=1"
          LinkCaption="Watch an experienced doctor discuss how he uses SUPPRELIN<sup>&reg;</sup> LA - potential side effects"
        >
              Watch an experienced doctor discuss how he uses SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
